<template>
  <!-- Generator: Adobe Illustrator 28.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="icon_shop_svg"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 473 500"
    xml:space="preserve"
    class="h-12 w-12"
    style="enable-background: new 0 0 473 500"
  >
    <g>
      <path
        d="M367.3,223.06c-2.69,0-4.86,2.17-4.86,4.86v132.5c0,12.04-9.76,21.8-21.8,21.8h-62.69V258.53c0-2.68-2.17-4.86-4.86-4.86
		h-83.75c-2.68,0-4.86,2.17-4.86,4.86v123.69h-61.51c-12.04,0-21.8-9.76-21.8-21.8v-132.5c0-2.69-2.17-4.86-4.86-4.86
		c-2.68,0-4.86,2.17-4.86,4.86v132.49c0,17.41,14.12,31.53,31.53,31.53h65.93c2.92,0,5.28-2.37,5.28-5.28V263.39h74.04v123.27
		c0,2.92,2.37,5.28,5.28,5.28h67.12c17.41,0,31.52-14.11,31.52-31.52v-132.5C372.16,225.23,369.99,223.06,367.3,223.06z"
      />
      <path
        d="M403.81,163.74l-78.69-66.28c-0.88-0.74-1.98-1.14-3.13-1.14H141.63c-1.15,0-2.25,0.4-3.13,1.14l-78.69,66.28
		c-1.1,0.92-1.73,2.28-1.73,3.72c0,21.3,17.33,38.63,38.63,38.63c14.51,0,27.17-8.04,33.77-19.89c6.6,11.85,19.27,19.89,33.77,19.89
		s27.17-8.04,33.77-19.89c6.6,11.85,19.27,19.89,33.77,19.89s27.17-8.04,33.77-19.89c6.6,11.85,19.27,19.89,33.77,19.89
		s27.17-8.04,33.77-19.89c6.6,11.85,19.27,19.89,33.77,19.89c21.3,0,38.63-17.33,38.63-38.63
		C405.54,166.02,404.91,164.66,403.81,163.74z M96.71,196.37c-15.21,0-27.71-11.8-28.83-26.73l75.52-63.61h24.03l-40.93,58.64
		l0.01,0c-0.55,0.79-0.88,1.74-0.88,2.78C125.63,183.4,112.66,196.37,96.71,196.37z M164.26,196.37
		c-15.45,0-28.11-12.18-28.88-27.44l43.9-62.9h30.48l-16.41,60.14l0.02,0.01c-0.11,0.41-0.19,0.83-0.19,1.27
		C193.18,183.4,180.21,196.37,164.26,196.37z M231.81,196.37c-15.75,0-28.58-12.65-28.9-28.32l16.92-62.02h23.97l16.92,62.02
		C260.39,183.72,247.56,196.37,231.81,196.37z M299.36,196.37c-15.95,0-28.92-12.97-28.92-28.92c0-0.44-0.08-0.86-0.19-1.27
		l0.02-0.01l-16.41-60.14h30.48l43.9,62.9C327.47,184.19,314.81,196.37,299.36,196.37z M366.91,196.37
		c-15.95,0-28.92-12.97-28.92-28.92c0-1.03-0.33-1.99-0.88-2.78l0.01,0l-40.93-58.64h24.03l75.52,63.61
		C394.62,184.57,382.12,196.37,366.91,196.37z"
      />
    </g>
  </svg>
</template>
